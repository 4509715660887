import React from "react";
import { InlineShareButtons } from "sharethis-reactjs";
import { useWindowWidth } from "@react-hook/window-size";

const SHARE_BUTTONS = {
  XS: {
    networks: ["facebook", "twitter", "whatsapp"],
    labels: "null",
    show_total: false
  },
  S: {
    networks: ["facebook", "twitter", "whatsapp", "messenger", "twitter"],
    labels: "null",
    show_total: false
  },
  M: {
    networks: ["facebook", "twitter", "reddit", "sharethis"],
    labels: "cta",
    show_total: false
  }
};

export const ShareButtons = () => {
  const windowWidth = useWindowWidth();
  let addToConfig;

  if (windowWidth <= 375) {
    addToConfig = SHARE_BUTTONS.XS;
  } else if (windowWidth <= 500) {
    addToConfig = SHARE_BUTTONS.S;
  } else {
    addToConfig = SHARE_BUTTONS.M;
  }

  return (
    <div className="Share-buttons">
      <InlineShareButtons
        config={{
          alignment: "center", // alignment of buttons (left, center, right)
          color: "social", // set the color of buttons (social, white)
          enabled: true, // show/hide buttons (true, false)
          font_size: 13, // font size for the buttons
          language: "en", // which language to use (see LANGUAGES)
          padding: 12, // padding within buttons (INTEGER)
          radius: 4, // the corner radius on each button (INTEGER)
          show_total: false,
          size: 30, // the size of each button (INTEGER)
          username: "marcbalaban", // (only for twitter sharing)
          ...addToConfig
        }}
      />
      <a
        className={"bmc-button"}
        target="_blank"
        rel="noreferrer noopener"
        href="https://www.buymeacoffee.com/marcbalaban"
      >
        <img
          src="https://cdn.buymeacoffee.com/buttons/bmc-new-btn-logo.svg"
          alt="Buy me a coffee"
        />
        <span>Buy me a coffee</span>
      </a>
    </div>
  );
};
