import { ANSWER_TYPES, ANSWER_SENTIMENT } from "./constants";

const REPLACEMENT_WORDS = {
  good_adjective: [
    "amazing",
    "the best",
    "fantastic",
    "great",
    "incredible",
    "very nice",
    "outstanding",
    "phenomenal",
    "very special",
    "tremendous",
    "unbelievably great",
    "unbelievably tremendous"
  ],
  good_rating: ["an a+", "a 10/10", "an a++", "a glowing review"],
  bad_rating: [
    "a big fat F",
    "a 1/10",
    "a score: 2 out of 50",
    "a horrible review"
  ],
  bad_adjective: [
    "bad",
    "crooked",
    "disgusting",
    "dumb",
    "horrible",
    "not good",
    "out of control",
    "overrated",
    "pathetic",
    "corrupt",
    "ridiculous",
    "rude",
    "sad",
    "scary",
    "stupid",
    "terrible",
    "weak",
    "the worst"
  ],
  bad_noun: [
    "chaos",
    "disaster",
    "hatred",
    "idiocy",
    "lightweight",
    "phonies",
    "problem",
    "terror"
  ],
  bad_person: [
    "clown",
    "choker",
    "dummy",
    "fool",
    "loser",
    "idiot",
    "lowlife",
    "moron",
    "joke",
    "dope"
  ],
  bad_news: [
    "CNN",
    "The Washington Post",
    "Newsweek",
    "The New York Times",
    "Bloomberg",
    "MSNBC",
    "ABC News",
    "CBSN"
  ],
  praise: [
    "thank you",
    "you're doing a bang up job",
    "you know [x]",
    "you're a very stable genius"
  ],
  extreme: ["big", "huge", "major", "massive", "staggering", "substantial"],
  money: [
    "a lot money",
    "billions of billions of dollars",
    "hundreds of millions of dollars",
    "your hard-earned american dollars",
    "tons of money"
  ],
  types_of_people: [
    "men and women",
    "americans",
    "people",
    "hard-workers",
    "successful people"
  ]
};

const createStrMatch = listOfCategories =>
  Object.keys(listOfCategories).reduce((obj, category) => {
    obj[`[${category}]`] = listOfCategories[category];
    return obj;
  }, {});

const STRING_REPLACEMENT_CATEGORIES = createStrMatch(REPLACEMENT_WORDS);

const GOOD_ANSWERS = {
  all: [
    "The whitehouse loves [x]",
    "polls are showing that [x] is [good_adjective]",
    "I like [x] a lot",
    "I would give [x] [good_rating]",
    "I talked to [good_adjective] people about [x]",
    "We need [x] if we want to be winning again",
    "[x] will make us a lot money",
    "The [good_adjective] [types_of_people] of this country love [x]",
    "The whitehouse has a [good_adjective] relationship with [x]",
    "We need [x] in america",
    "Much respect to [x]",
    "[good_adjective] news",
    "my administration thinks [x] is [good_adjective]"
  ],
  person: [
    "I heard from [x] the other day",
    "[x] phoned me up recently",
    "I’ve got a lot of respect for [x]",
    "[x] is a big winner",
    "I'd invite [x] to the whitehouse anyday",
    "[x] is a great communicator",
    "I'd put [x] in my administration",
    "[x] could be a cabinet member",
    "[x] makes [good_adjective] decisions",
    "[x] is an ulimate winner",
    "[x] is doing a great job",
    "[x] and I will always be friends, no matter what"
  ],
  place: [
    "I was in [x] the other week",
    "[good_adjective] weather",
    "The USA has a great relationship with [x]",
    "Wonderful place",
    "Tremendous place",
    "I'm going to travel to [x] next week",
    "One of the best places in the world"
  ],
  thing: [
    "Federal agencies are working together to source the best [x]",
    "America has the best [x]",
    "I know [x]. I have the best [x]",
    "I heard from the best experts about [x], they said they're [good_adjective] for our economy",
    "[x] are great",
    "[x] are very good",
    "[x] are very very good"
  ]
};

const BAD_ANSWERS = {
  all: [
    "people who like [x] are [bad_adjective]",
    "the only people who approve of [x] are [bad_person]s",
    "We have full power over [x]",
    "[x] is fake news",
    "A lot of people tell me we can't trust [x]",
    "[x] is a [extreme] problem",
    "[x] is a [extreme] concern",
    "[x] will cost us [money]",
    "[x] is costing us [money]",
    "we're going to take care of [x]",
    "What's happening is a [extreme] disgrace",
    "[x] is not good, not good",
    "[x] is [bad_adjective]",
    "I'd give [x] [bad_rating]",
    "the deep state loves [x]",
    "[x] is bad for the American people",
    "[x] is a disgrace",
    "Not good, not good",
    "Dumb, [x], dumb",
    "[bad_adjective], [x], [bad_adjective]",
    "Stupid, [x], stupid",
    "the democrats love [x]",
    "[x] is treating us very poorly",
    "The incredible [types_of_people] of this country hate [x]",
    "very dangerous & corrupt",
    "We need to purge [x]",
    "On the recommendation of the WhiteHouse, we think [x] is [bad_adjective]"
  ],
  person: [
    "[x] is a total sleazebag",
    "[x] is unattractive both inside and out",
    "[x] deserves no love from the american people",
    "[x] is crooked",
    "[x] is crazy",
    "I think [x] is a democrat",
    "[x] is sleepy and senile",
    "[x] is a nasty woman",
    "[x] is a [bad_person]",
    "Are you talking about [bad_person] [x]?",
    "let's take a closer look at [x]'s birth certificate",
    "[x] is weak, both mentally and physically",
    "[x] would go down fast and hard"
  ],
  place: [
    "[x] has the worst laws",
    "I’d never travel to [x]",
    "[x]'s relationship to the USA is terrible",
    "We’re gonna make [x] pay tremendous tariffs",
    "[x] is an awful place"
  ],
  thing: [
    "Federal agencies are working together to protect us from [x]",
    "I would never touch [x] with a 20 foot pole",
    "[x] are [bad_adjective]",
    "[x] are dumb",
    "Why are you asking me about horrible [x]"
  ]
};

const NEUTRAL_ANSWERS = {
  all: [
    "Believe me",
    "Let me tell you",
    "I know [x] very well",
    "Nobody knows more about [x] than me",
    "I know more about [x] than anyone I know",
    "People know I know this",
    "I’ve got experts on this",
    "I’m sure of this",
    "This I know",
    "That’s just what I had heard",
    "Everybody’s talking about [x]",
    "we’ve been hearing a lot about [x]",
    "I've had many people call me and say [praise]",
    "Now see",
    "I must tell you",
    "in all fairness",
    "to be perfectly honest",
    "more than anything else",
    "(random muttering)",
    "(inaudable)",
    "Listen",
    "Look",
    "uh",
    "um",
    "yeah",
    "ok",
    "yes"
  ],
  person: [],
  place: [],
  thing: []
};

const sentimentMap = {
  [ANSWER_SENTIMENT.HAPPY]: GOOD_ANSWERS,
  [ANSWER_SENTIMENT.ANGRY]: BAD_ANSWERS
};

const BAD_QUESTION = [
  "What kind of fake news questions is '[x]'",
  "'[x]' is a [bad_adjective] question",
  "you are [bad_adjective]! that's a bad, bad question",
  "'[x]'?! are you with '[bad_news]'? because you're a [bad_adjective] reporter",
  "'[x]' is the dumbest question I've ever heard",
  "sit down right now you [bad_person]! don't ask me '[x]' ever again"
];

const SINGULAR_TO_PLURAL = { "[x] is": "our [x] are" };

const replaceForPlural = text => {
  for (let replacement in SINGULAR_TO_PLURAL) {
    if (text.includes(replacement)) {
      text = text.split(replacement).join(SINGULAR_TO_PLURAL[replacement]);
    }
  }
  return text;
};

const setLowercaseAndCapitalizeFirstLetter = text => {
  text = text.toLowerCase();
  return text.charAt(0).toUpperCase() + text.substring(1);
};

const replaceVariableWords = (fullText, variableReplacements) => {
  for (let variable in variableReplacements) {
    const textSplitByVariable = fullText.split(variable);
    if (textSplitByVariable.length > 1) {
      let newText = "";
      for (let i = 0; i < textSplitByVariable.length; i++) {
        newText += textSplitByVariable[i];
        if (i !== textSplitByVariable.length - 1) {
          newText += selectRandom(variableReplacements[variable]);
        }
      }
      fullText = newText;
    }
  }

  return fullText;
};

export const createAnswer = ({ type, adjectives, text, sentiment }) => {
  let list;
  let trumpSpeech;

  const sentimentAnswers = sentimentMap[sentiment];

  if (type === ANSWER_TYPES.BAD_ANSWER) {
    list = BAD_QUESTION;
    trumpSpeech = createRandomSelectionFromlist(list, {
      num: 0,
      toAdd: 1
    });
  } else if (type === ANSWER_TYPES.PERSON) {
    list = [
      ...sentimentAnswers[type],
      ...pickSet(
        NEUTRAL_ANSWERS[type],
        Math.round(NEUTRAL_ANSWERS[type].length / 2)
      ),
      ...sentimentAnswers["all"],
      ...pickSet(
        NEUTRAL_ANSWERS["all"],
        Math.round(NEUTRAL_ANSWERS["all"].length / 2)
      )
    ];
    trumpSpeech = createRandomSelectionFromlist(list, {
      num: 5,
      toAdd: 3
    });
  } else if (type === ANSWER_TYPES.PLACE) {
    list = [
      ...sentimentAnswers[type],
      ...pickSet(
        NEUTRAL_ANSWERS[type],
        Math.round(NEUTRAL_ANSWERS[type].length / 2)
      ),
      ...sentimentAnswers["all"],
      ...pickSet(
        NEUTRAL_ANSWERS["all"],
        Math.round(NEUTRAL_ANSWERS["all"].length / 2)
      )
    ];
    trumpSpeech = createRandomSelectionFromlist(list, {
      num: 5,
      toAdd: 3
    });
  } else if (type === ANSWER_TYPES.THING) {
    list = [
      ...sentimentAnswers[type],
      ...pickSet(
        NEUTRAL_ANSWERS[type],
        Math.round(NEUTRAL_ANSWERS[type].length / 2)
      ),
      ...sentimentAnswers["all"],
      ...pickSet(
        NEUTRAL_ANSWERS["all"],
        Math.round(NEUTRAL_ANSWERS["all"].length / 2)
      )
    ];
    trumpSpeech = createRandomSelectionFromlist(list, {
      num: 5,
      toAdd: 3
    });
    trumpSpeech = replaceForPlural(trumpSpeech);
  }

  const nounWithAdjectives = `${
    adjectives.length ? adjectives.join(" ") + " " : ""
  }${text}`;

  trumpSpeech = replaceVariableWords(
    trumpSpeech,
    STRING_REPLACEMENT_CATEGORIES
  );
  trumpSpeech = trumpSpeech.replace(/\[x\]/g, nounWithAdjectives);

  trumpSpeech = setLowercaseAndCapitalizeFirstLetter(trumpSpeech);

  return trumpSpeech;
};

const TEXT_JOIN_KEYS = [
  "! ",
  "!! ",
  ". ",
  "..",
  "...",
  "....",
  ".....",
  ", ",
  " -- "
];

const ENDING_SENTENCE = TEXT_JOIN_KEYS.slice(0, 3);

const createRandomSelectionFromlist = (_list, { toAdd, num }) => {
  const list = _list.slice();
  const n = Math.floor(Math.random() * num + toAdd);
  let randomTrumpQuotes = [];
  for (let i = 0; i < n; i++) {
    let randomText = list.splice(Math.floor(Math.random() * list.length), 1);
    randomTrumpQuotes.push(randomText);
  }

  let sentence = "";
  for (let i = 0; i < randomTrumpQuotes.length; i++) {
    sentence += randomTrumpQuotes[i];
    sentence +=
      i < randomTrumpQuotes.length - 1
        ? selectRandom(TEXT_JOIN_KEYS)
        : selectRandom(ENDING_SENTENCE);
  }

  return sentence;
};

const selectRandom = list => list[Math.floor(Math.random() * list.length)];

const pickSet = (_list, n) => {
  const result = [];
  const list = _list.slice();

  for (let i = 0; i < n; i++) {
    let randomText = list.splice(Math.floor(Math.random() * list.length), 1);
    result.push(...randomText);
  }
  return result;
};
