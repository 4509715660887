import React, { useState, useRef, useEffect } from "react";
import htmlToImage from "html-to-image";
import download from "downloadjs";
import { ShareButtons } from "./Share-buttons";
import { getNoun } from "./question-parser";
import { createAnswer } from "./create-answer";
import Typist from "react-typist";
import { IMAGE_MAP } from "./constants";
import ReactHowler from "react-howler";
import "./App.css";

const TALK_DELAY = 500;

let reporterSound = `./talking1.mp3`;

const updateRandomReporterSound = () => {
  reporterSound = `./talking${Math.ceil(Math.random() * 9)}.mp3`;
};

let cameraSound;

const updateRandomCameraSound = () => {
  cameraSound = Math.ceil(Math.random() * 3);
};

updateRandomReporterSound();
updateRandomCameraSound();

function App() {
  const [question, updateQuestion] = useState("");
  const [isFocusedOnInput, updateIsFocusedOnInput] = useState(false);
  const [answer, updateAnswer] = useState("");
  const [pictureUrl, updatePictureUrl] = useState(IMAGE_MAP.neutral);
  const [fadeTitleOut, updateFadeTitleOut] = useState(false);
  const [isTalking, updateIsTalking] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [showDownload, updateShowDownload] = useState(false);
  const [shouldPlayReporterSound, updateShouldPlayReporterSound] = useState(
    false
  );
  const [shouldPlayCameraSound, updateShouldPlayCameraSound] = useState(false);

  const inputRef = useRef(null);
  const bodyRef = useRef(null);
  const imageWrapperRef = useRef(null);
  const imageRef = useRef(null);

  useEffect(() => {
    Object.keys(IMAGE_MAP).forEach(picture => {
      const img = new Image();
      img.src = IMAGE_MAP[picture];
    });
  }, []);

  function downloadImage() {
    setDownloading(true);
    updateShowDownload(false);
    htmlToImage
      .toJpeg(bodyRef.current, { quality: 0.7 })
      .then(function(dataUrl) {
        download(
          dataUrl,
          `trump-quote-${Math.floor(Math.random() * 100000)}.jpeg`
        );
        setDownloading(false);
      })
      .catch(function(error) {
        console.error("oops, something went wrong!", error);
      });
  }

  function handleChange(e) {
    updateQuestion(e.target.value);
    updateAnswer("");
    updateShowDownload(false);
    updateIsTalking(false);
    updatePictureUrl(IMAGE_MAP.neutral);
  }

  function startTalking() {
    let questionTrimmed = question.trim();
    if (isTalking || answer || questionTrimmed.length === 0) {
      return;
    }

    inputRef.current.blur();
    updateIsFocusedOnInput(false);
    const noun = getNoun(questionTrimmed);
    updatePictureUrl(IMAGE_MAP[noun.sentiment]);
    const a = createAnswer(noun);
    updateAnswer(a);
    setTimeout(() => {
      updateIsTalking(true);
    }, TALK_DELAY);
    setTimeout(() => {
      updateShouldPlayCameraSound(true);
    }, Math.floor(Math.random() * 10000));
  }

  function handleInputClick() {
    if (!isFocusedOnInput) {
      inputRef.current.setSelectionRange(0, 9999);
    }
    updateIsFocusedOnInput(true);
    updateShouldPlayReporterSound(true);
    updateRandomCameraSound();
    updateRandomReporterSound();
  }

  function handleKeydown(e) {
    if (!fadeTitleOut) {
      updateFadeTitleOut(true);
    }
    if (e.key === "Enter") {
      startTalking();
    }
  }

  return (
    <div className="App">
      <div className="Body" ref={bodyRef}>
        <div className="Trump">
          <div className={`Title ${fadeTitleOut ? "fade-out" : "fade-in"}`}>
            {!answer.length ? (
              <>
                <div className="Title-main">Ask Trump</div>
                <div className="Title-sub">A press conference simulator</div>
              </>
            ) : (
              ""
            )}
          </div>
          {answer.length ? (
            <div className={"speech-bubble-wrapper"}>
              <blockquote className="speech-bubble">
                <Typist
                  startDelay={TALK_DELAY}
                  cursor={{
                    show: false
                  }}
                  onTypingDone={() => {
                    updateShowDownload(true);
                    updateIsTalking(false);
                  }}
                >
                  {answer}
                </Typist>
                {showDownload && !downloading && (
                  <img
                    onClick={downloadImage}
                    className={"icon Download-icon Download"}
                    src="./download.png"
                    alt="ask question icon"
                  />
                )}
              </blockquote>
            </div>
          ) : null}
          <div className={"Image-wrapper"} ref={imageWrapperRef}>
            <img
              src={pictureUrl}
              className="Trump-body"
              alt="trump"
              ref={imageRef}
            />
            <div
              className={`flash-animation ${
                isTalking && shouldPlayCameraSound ? `flash${cameraSound}` : ""
              }`}
              style={{
                height: `${(imageWrapperRef.current &&
                  imageWrapperRef.current.clientHeight) ||
                  0}px`,
                width: `${(imageRef.current && imageRef.current.clientWidth) ||
                  0}px`
              }}
            />
          </div>
        </div>
        <div className={"Reporters"}>
          <div className="Reporters-banner">Live Press Conference</div>
          <div className="input">
            <input
              ref={inputRef}
              onClick={handleInputClick}
              placeholder={"Ask about people, places, and things"}
              value={question}
              onBlur={() => {
                updateIsFocusedOnInput(false);
              }}
              onChange={handleChange}
              onKeyDown={handleKeydown}
            />
            <div
              className={"input-button"}
              onClick={() => {
                startTalking();
              }}
            >
              <img
                className={"icon"}
                src="./talk-icon.png"
                alt="ask question icon"
              />
            </div>
          </div>
          <img
            src={"./reporters.png"}
            alt="microphones"
            className={"Reporters-microphones"}
          />
        </div>
        {downloading ? (
          <div className="Asktrump-Domain">asktrump.co</div>
        ) : (
          <ShareButtons />
        )}
      </div>
      <ReactHowler
        src={`./camera${cameraSound}.mp3`}
        volume={0.3}
        playing={isTalking && shouldPlayCameraSound}
        onEnd={() => {
          updateShouldPlayCameraSound(false);
        }}
      />
      <ReactHowler
        src={reporterSound}
        volume={0.3}
        playing={shouldPlayReporterSound}
        onEnd={() => {
          updateShouldPlayReporterSound(false);
        }}
      />
    </div>
  );
}

export default App;
