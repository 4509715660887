import nlp from "compromise";
import { ANSWER_TYPES, ANSWER_SENTIMENT } from "./constants";

export const getNoun = question => {
  const nlpQuestion = nlp(question);
  const people = nlpQuestion.people().json();
  const place = nlpQuestion.places().json();
  const nouns = nlpQuestion.nouns().json();

  let adjectives = nlpQuestion
    .nouns()
    .adjectives()
    .out("array");

  const sentiment =
    ANSWER_SENTIMENT[Object.keys(ANSWER_SENTIMENT)[Math.round(Math.random())]];

  adjectives = adjectives.map(removePunctuationFromString);

  if (people.length) {
    const noun = removePunctuationFromString(people[0].text);
    return {
      text: nlp(noun)
        .nouns()
        .toPlural()
        .json()[0].text,
      type: ANSWER_TYPES.PERSON,
      adjectives: filterOutBadAdjectives(adjectives, question, noun),
      sentiment
    };
  } else if (place.length) {
    const noun = removePunctuationFromString(place[0].text);
    return {
      text: nlp(noun)
        .nouns()
        .toPlural()
        .json()[0].text,
      type: ANSWER_TYPES.PLACE,
      adjectives: filterOutBadAdjectives(adjectives, question, noun),
      sentiment
    };
  } else if (nouns.length) {
    const noun = nlp(nouns[0].text).nouns().length ? nouns[0].text : question;
    const text = nlp(noun)
      .nouns()
      .toPlural()
      .json()[0].text;
    return {
      text: removePunctuationFromString(text),
      type: ANSWER_TYPES.THING,
      adjectives: filterOutBadAdjectives(adjectives, question, noun),
      sentiment
    };
  }

  return {
    text: question,
    type: ANSWER_TYPES.BAD_ANSWER,
    adjectives: [],
    sentiment: ANSWER_SENTIMENT.ANGRY
  };
};

const regex = /[^A-Za-z0-9\s]/g;
const removePunctuationFromString = str => str.replace(regex, "").trim();
const filterOutBadAdjectives = (adjectives, question, noun) => {
  let results = [];
  const adjectivesLength = adjectives.length;
  const questionTokenized = question.split(" ");
  const indexOfNoun = questionTokenized.indexOf(noun);
  for (let i = indexOfNoun - adjectivesLength; i < indexOfNoun; i++) {
    if (adjectives.includes(questionTokenized[i])) {
      results.push(questionTokenized[i]);
    }
  }
  return results;
};
