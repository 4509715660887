export const ANSWER_TYPES = {
  PERSON: "person",
  PLACE: "place",
  THING: "thing",
  BAD_ANSWER: "bad_answer"
};

export const ANSWER_SENTIMENT = {
  HAPPY: "happy",
  ANGRY: "angry"
};

export const IMAGE_MAP = {
  [ANSWER_SENTIMENT.HAPPY]: "./trump-happy.jpg",
  [ANSWER_SENTIMENT.ANGRY]: "./trump-mad.jpg",
  neutral: "./trump-talking.jpg"
};
